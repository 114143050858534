



























import { Component, Vue } from "vue-property-decorator";
import FaqSection from "./../components/sections/FaqSection.vue";

declare const jQuery: any;
declare const $: any;

@Component({
  components: {
    FaqSection,
  },
  metaInfo: {
    title: "Blogs",
    titleTemplate: "%s | RespectMart",
  },
})
export default class Blog extends Vue {
  public signInUrl = process.env.VUE_APP_SIGNIN_URL;
  public signUpUrl = process.env.VUE_APP_SIGNUP_URL;
  public blogs: any[] = [];
  mounted() {
    // console.log("This ran=====");
    // if (jQuery(".popup-btn").length > 0) {
    //   console.log("Found popup");
    //   $("a.popup-btn").fancybox({
    //     transitionIn: "elastic",
    //     transitionOut: "elastic",
    //     speedIn: 600,
    //     speedOut: 200,
    //   });
    // }

    this.fetchData();
  }
  
  async fetchData() {
    try {
      const response = await fetch('https://respectmart.com/api/blog.json', {
        cache: 'no-cache',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      this.blogs = data;
      // console.log(this.blogs);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
}
