

















































































































































































































import { Component, Vue } from "vue-property-decorator";

interface FAQ {
  q: string;
  a: string;
}

@Component
export default class FaqSection extends Vue {
  public faqs: Record<string, FAQ[]> = {
    general: [
      {
        q: "How does the app work?",
        a: `Our website makes it very much easy for you to shop on any E-commerce platform all around the world, and having the items shipped here to Nigeria. It is quite easy to use- all you need to do is visit our website on your mobile device or PC and create an account providing the necessary details.
That’s it! You are ready to shop!
`,
      },
      {
        q: "How Do I Pay?",
        a:
          "We have a user-friendly award winning payment merchant integration that makes paying with your debit card, bank account or USSD fast and seamless. All banks work with this system. No our customers do not worry about Forex cap as we provide the best rate in the market.",
      },
      {
        q: "How do I shop for items?",
        a:
          "RespectMart avails you the opportunity to shop from more than 200 stores in the USA. You can directly visit this store and import the items in your RespectMart Account. We take care of purchasing, inspection and shipping and resellers can also use our mobile app to market their products when it arrives Nigeria.",
      },
      {
        q: "How do I contact you?",
        a:
          "You can contact us by filling the contact form or you can call us. Our contact details are found at the top of the page.",
      },
    ],
    orders: [],
    shipping: [
      {
        q:
          "Once I place an order, how do I know the different stages of the order?",
        a: `You will be updated on the status of your order during the order life circle. ‘Order Status' will show the stage at which your order is:<br/> 
            <ul>
            <li>Order is submitted and reviewed equivalent payment charged</li>
            <li>Items procured and shipped to our warehouse</li>
            <li>Items arrive and are checked for quality and damages</li>
            <li>Items are shipped </li>
            <li>Items arrive Nigeria </li> 
            <li>Items are cleared and available for delivery </li>
            </ul>
`,
      },
      {
        q: "Can I cancel or make changes when an order has been placed?",
        a:
          "Once an order has been made no changes or cancellations are allowed as we do our best to get items out to you as fast as possible. This means we are unable to stop, modify or cancel purchased items.",
      },
      {
        q: "When do I pick up my items?",
        a:
          "Shipping is a very important factor in ensuring that the business life cycle is short. Typically shipping takes 5 to 8 business days from USA to Lagos. Items can also be sent directly to importers in all the 36 states of the federation. We provide a real time shipping update from when you give us the product information to when the item arrives Nigeria. You can also pick up your items at our offices in Lagos and Ibadan.",
      },
      {
        q: "Where can I pick up my items?",
        a:
          "You can pick up your items at our pick up locations all across Nigeria. Our Pick-up location in Lagos is: 6, Lanre Adeyemi Street, Progressive Estate, Ojodu, Berger, Lagos.",
      },
      {
        q: "Can the items be delivered to my preferred address?",
        a:
          "Absolutely! The items can be delivered to any address of your choice, be it your home or office.",
      },
      {
        q: "How do I know when the items have arrived?",
        a:
          "We provide a real time shipping update from when you give us the product information to when the item arrives Nigeria. Once the item has arrived Nigeria, we will notify you via text messages and email. And when the items are ready for pick up, you will also be notified.",
      },
      {
        q: "How long does it take to get the item?",
        a:
          "This depends on the item and the store where it is being shipped from. Typically, it takes 5-10 business days.",
      },
      {
        q: "Is there an insurance for these items?",
        a:
          "Insurance is optional, RespectMarters that opt for insurance will have full refund if an item is damaged on delivery, you will be refunded for the cost of the item only. Kindly note that this is only for damaged items only.",
      },
      {
        q: "What is the maximum weight of item to be shipped?",
        a: "No maximum no minimum.",
      },
      {
        q: "What items are not allowed to be shipped?",
        a: `For safety reasons, we cannot ship: <br/>
            <ol>
            <li> Hazardous materials</li>
            <li> Chemicals</li>
            <li> Medication</li>
            <li> Fire Arms</li>
            <li> Alcohol</li>
            <li> Any item or object with hazardous material in it.</li>
            <li> Weapons and weapon equipment</li>
            <li> Live Products including foods and plants</li>
            <li> Food products</li>
            <li> Wooden furniture</li>
            <li> Powders</li>
            <li> Furniture</li>
            <li> Pressurized Cans</li>
            <li> Drones</li>
            <li> Large goods</li>
            </ol>
`,
      },
      {
        q: "What are you shipping costs?",
        a:
          "Our shipping fees vary based on each store. There is however a starting point to all our shipping which is $5/lb. So you will have a baseline cost of no less than $15 for shipping/taxes/duties/fees. We highly recommend not ordering just 1 single item. Maximize your shopping by ordering between 10 - 15 items with each order.",
      },
    ],
    refunds: [
      {
        q: "What is the return policy?",
        a: `The return policy is of two types:<br/>
        <ol>
            <li>If the item you receive is damaged or it is the wrong item delivered, upon acceptance of items you will be refunded the full amount of the product as soon as we have received the refund from the merchant and we will pay to have the item shipped back to the US via DHL. Please note you would have to report the item as damaged or wrong item within 2 business days from delivery, so please review all products as soon as you receive them.</li>
            <li>If you have received the item but you changed your mind and would like to return it, you would be responsible for paying to have the item shipped back to the US via DHL. You should check the merchant’s return policy period.</li>
        </ol>
Returning an item is easy. Go to the app, login to your account and go to your order. Find the item you want to return and check the box to return the item. Provide a reason why you want to return the item. Once we receive your request we will provide you with all the details you need to return the item to our warehouse. If you want a refund please note we only refund once we have received the refund from the merchant. Refunds may take up to 30 days.
`,
      },
      {
        q: "What is your cash refund policy?",
        a: `Customers hereby understand that RespectMart acts in its capacity as a middle-man for purposes of assisting with purchases and delivery of customer items from the stores advertised to the customer.<br/>

RespectMart accepts no liability for substandard or defective items purchased by the customer from any of the stores listed on our app.<br/>

We do however take steps to ensure that purchased items are delivered in functional condition in line with the provisions of our returns policy.
`,
      },
    ],
  };
}
