
















































import { Component, Vue, Watch } from "vue-property-decorator";
import FaqSection from "./../components/sections/FaqSection.vue";

declare const jQuery: any;
declare const $: any;
// var metaTitle = '';
// var metaDescription = '';

@Component({
  components: {
    FaqSection,
  },
  // metaInfo: {
  //   title: metaTitle,
  //   titleTemplate: "%s | RespectMart",
  //   meta: [
  //     {
  //       name: "description",
  //       content: metaDescription,
  //     },
  //   ],
  // },
})
export default class Blog extends Vue {
  public signInUrl = process.env.VUE_APP_SIGNIN_URL;
  public signUpUrl = process.env.VUE_APP_SIGNUP_URL;
  public blogs: any[] = [];
  public blog: any = {};
  public nextBlog: any = '';
  public previousBlog: any = '';

  mounted() {
    console.log("This ran=====");
    if (jQuery(".popup-btn").length > 0) {
      console.log("Found popup");
      $("a.popup-btn").fancybox({
        transitionIn: "elastic",
        transitionOut: "elastic",
        speedIn: 600,
        speedOut: 200,
      });
    }

    this.fetchData();
  }

  @Watch('$route.params.slug')
  onSlugChanged(newSlug: string, oldSlug: string) {
    console.log(1);
    this.fetchData();
  }


  setTitle(str: string) {
    document.title = `${str} | RespectMart`;
  }

  setDescription(str: string) {
    let descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (!descriptionMetaTag) {
      descriptionMetaTag = document.createElement('meta');
      descriptionMetaTag.setAttribute('name', 'description');
      document.head.appendChild(descriptionMetaTag);
    }
    descriptionMetaTag.setAttribute('content', str);
  }

  async fetchData() {
    try {

      const response = await fetch('https://respectmart.com/api/blog.json', {
        cache: 'no-cache',
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      // get all blogs
      const data = await response.json();

      // filter current blog
      const filteredBlog = data.filter((e: {slug: string}) => e.slug == this.$route.params.slug);
      if(filteredBlog.length >= 1){
        this.blog = filteredBlog[0];
        // metaTitle = this.blog.title;
        // metaDescription = this.blog.description;
        this.setTitle(this.blog.title);
        this.setDescription(this.blog.short_description);
      }else{
        this.$router.push('/blogs');
      }
      
      // show realted blogs
      const expectData = data.filter((e: {slug: string}) => e.slug != this.$route.params.slug);
      this.shuffle(expectData);
      this.blogs = data.slice(0, 3);

      // get previous blog link
      if(this.blog.id > 1){
        const nb = data.filter((e: {id: number}) => e.id == this.blog.id - 1);
        this.previousBlog = nb[0].slug;
      }else{
        this.previousBlog = '';
      }

      // get next blog link
      if(this.blog.id < data.length){
        const nb = data.filter((e: {id: number}) => e.id == this.blog.id + 1);
        this.nextBlog = nb[0].slug;
      }else{
        this.nextBlog = '';
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  shuffle(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}
